import { registerApplication, start, navigateToUrl } from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import { browserLogger as log } from '@kmb/logger';

import getConfig from './getConfig';

import Context from './Context';
import Authentication from './Authentication';

const routes = constructRoutes(document.querySelector("#single-spa-layout"));

// eslint-disable-next-line
(async function main() {
  // 0. Get configuration
  const config = getConfig();

  // 1. Register micro frontend applications.
  const applications = constructApplications({
    routes,
    // eslint-disable-next-line
    loadApp: ({ name }) => ({
      // eslint-disable-next-line
      'mfe-sidebar': () => import('mfeSidebar/Root'),
      'mfe-profile': () => import('mfeProfile/Root'),
      'mfe-header': () => import('mfeHeader/Root'),
      'mfe-auth': () => import('mfeAuth/Root'),
      'mfe-task': () => import('mfeTask/Root'),
      'mfe-document': () => import('mfeDocument/Root'),
      'mfe-settings': () => import('mfeSettings/Root'),
      'mfe-customer': () => import('mfeCustomer/Root'),
      'mfe-maria': () => import('mfeMaria/Root'),
      'mfe-cloud': () => import('mfeCloud/Root'),
      'mfe-knowledge-base': () => import('mfeKnowledgeBase/Root'),
      'mfe-archive': () => import('mfeArchive/Root'),
    }[name]()),
  });

  constructLayoutEngine({
    applications,
    routes,
  });

  applications.forEach(registerApplication);

  // 2. Define global context shared across all MFE.
  let context;

  const auth = new Authentication();

  try {
    context = await Context.createInstance(auth, config);
  } catch(error) {
    log({ level: 'error', message: error.message });
    return;
  }

  if (!context) {
    return;
  }

  // eslint-disable-next-line
  global.rumb_root = { context };

  // 3. Start application.
  const signedInUser = await auth.isUserAuthenticated();

  function getLandingPageUrl() {
    return (global.rumb_root.context.organizationType === "COMPANY_TYPE_CONSULTING")
      ? `/org/${global.rumb_root.context.organizationId}/customer`
      : `/org/${global.rumb_root.context.organizationId}/dashboard`
  }

  function isNakedUrl() {
    const pathname = window.location.pathname;
    return pathname === '/' || !pathname
  }

  function getUnauthenticatedPage() {
    if (isNakedUrl()) return '/login';

    const urlSearch = new URL(window.location.href).searchParams
    const existingUrl = urlSearch?.get("next");
    if (existingUrl) return `/login?next=${existingUrl}`;

    if (window.location.pathname === '/login') return window.location.pathname;
    
    return `/login?next=${window.location.pathname}${window.location.search}`;
  }

  if (!signedInUser) {
    if (window.location.pathname !== '/signup') navigateToUrl(getUnauthenticatedPage());
  } else {
    if (isNakedUrl()) navigateToUrl(getLandingPageUrl());
  }

  context.on('userSignedIn', () => {
      const urlSearch = new URL(window.location.href).searchParams
      const existingUrl = urlSearch?.get("next");
      navigateToUrl(existingUrl ?? getLandingPageUrl());
  });

  context.on('userSignedOut', () => {
    navigateToUrl('/login');
  });

  // initial read
  context.onUrlChange();

  // listener for future url changes
  window.addEventListener("popstate", () => context.onUrlChange())

  start();
}());
